<template>
  <div class="about">
    <div class="h">
      <h1 align="center" class="animate__animated animate__bounceInRight">
        ABOUT US
      </h1>
      <h2 align="center" class="animate__animated animate__bounceInRight">
        关于我们
      </h2>
    </div>

    <a-menu mode="horizontal" v-model="current" class="about-menu">
      <a-menu-item v-for="item in menuList" :key="item.id" class="about-menu-item">
        {{ item.name }}
      </a-menu-item>
    </a-menu>
    <!--    <router-view></router-view>-->
    <AboutUs v-if="current[0] === 1"></AboutUs>
    <AboutHistory v-if="current[0] === 2"></AboutHistory>
    <AboutStructure v-if="current[0] === 3"></AboutStructure>
    <AboutEmpStyle v-if="current[0] === 4"></AboutEmpStyle>
    <AboutContactUs v-if="current[0] === 5"></AboutContactUs>
  </div>
</template>

<script>
import AboutUs from "@/components/about/AboutUs.vue";
import AboutContactUs from "@/components/about/AboutContactUs.vue";
import AboutHistory from "@/components/about/AboutHistory.vue";
import AboutEmpStyle from "@/components/about/AboutEmpStyle.vue";
import AboutStructure from "@/components/about/AboutStructure.vue";

export default {
  components: { AboutUs, AboutContactUs, AboutHistory, AboutEmpStyle, AboutStructure },
  data() {
    return {
      activeKey: 1,
      menuList: [
        { id: 1, name: "公司介绍", path: "aboutUs" },
        { id: 2, name: "历史沿革", path: "aboutHistory" },
        { id: 3, name: "组织架构", path: "aboutStructure" },
        { id: 4, name: "员工风采", path: "aboutEmpStyle" },
        { id: 5, name: "联系我们", path: "aboutContactUs" }
      ],
      current: [1]
    };
  },
  created() {
    this.$router.push({ name: "aboutUs" });
  },
  methods: {
    show(item) {
      this.$router.push({ name: item.path });
    }
  }
};
</script>

<style lang="less">
.about {
  position: relative;

  .h {
    width: 100%;
    position: absolute;
    top: -320px;
  }

  h1 {
    color: #ffffff;
    font-size: 72px;
    font-family: Quantum;
    font-weight: 400;
  }

  h2 {
    color: #ffffff;
    font-size: 44px;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }

  .menu {
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
  }
}

@media all and (max-width: 700px) {
  .about {
    position: relative;

    .h {
      width: 100%;
      position: absolute;
      top: -320px;
    }

    h1 {
      color: #ffffff;
      font-size: 100px;
      font-family: Quantum;
      font-weight: 400;
    }

    h2 {
      color: #ffffff;
      font-size: 54px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }

    .menu {
      width: 100%;
      margin: 0 auto;
      font-size: 18px;
    }

    .about-menu {
      height: 120px;
      line-height: 120px;

      .about-menu-item {
        width: 250px;
        text-align: center;
        font-size: 48px;
      }
    }
  }
}</style>
