<template>
  <div class="about-struc">
    <img src="../../assets/image/32645.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.about-struc {
  width: 100%;
  height: 1106px;
  background: url("../../assets/image/156.png");
  text-align: center;

  img {
    margin-top: 60px;
    width: 1039px;
    height: 973px;
  }
}

@media all and (max-width: 700px) {
  .about-struc {
    width: 100%;
    height: 1106px * 1.5;
    background: url("../../assets/image/156.png");
    text-align: center;

    img {
      margin-top: 60px;
      width: 1039px * 1.5;
      height: 973px * 1.5;
    }
  }
}
</style>
